import React from 'react'
import Card from '@ui/atoms/card/Card';
import Pill from '@ui/atoms/pill/Pill';
import Thumbnail from '@ui/atoms/thumbnail/Thumbnail';
import Link from '@ui/atoms/link/Link';
import ArtistName from '@ui/atoms/artist-name/ArtistName';
import CardBody from '@ui/atoms/card/card-body/CardBody';
import CardTitle from '@ui/atoms/card/card-title/CardTitle';
import CardCategories from '@ui/atoms/card/card-categories/CardCategories';

function Songs(props: any) {

  const { lang, item, lastElementRef } = props;
  const variant = 'songs';

  return (
    <Card lastElementRef={lastElementRef} >
      <Thumbnail
        lang={lang}
        href={`/${variant}/${item?.slug}`}
        src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/${variant}/thumbnails/${item?.thumbnails?.length && item?.thumbnails[0].slug}`}
        alt={item?.title}
        defaultImage={`${process.env.NEXT_PUBLIC_ASSETS_URL}/defaults/thumbnail.png`}
      />
      <CardBody>
        <CardCategories item={item} variant={variant} />
        <div style={{ display: 'flex' }}>
          <CardTitle
            lang={lang}
            href={`/${variant}/${item?.slug}`}
            title={item?.title}
          />
          {/* <div title={item?.title} className="font-weight-bold mb-0">
            <Link
              lang={lang}
              className="two-line-text"
              href={`/${variant}/${item?.slug}`}
            >
              {item?.title}
            </Link>
          </div> */}
        </div>
        <div className="media-body">
          <div className="mb-0">
            <div style={{ display: 'flex', overflow: 'hidden' }}>
              {
                item?.artists?.map((artist: any, index: number) => (
                  <ArtistName
                    key={`${index}-${artist?.id}`}
                    artistName={artist?.displayName}
                    slug={`/${variant}?artists=${artist?.id}`}
                  />
                ))
              }
            </div>
            {/* <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '1.3rem',
                  marginTop: '0.5rem',
                }}>
                  <ViewsCount count={0} />
                  <TimesInAgo date={item?.createdAt} />
                </div> */}
          </div>
          {/* <Link lang={lang}className="d-inline-block small pt-1" href={`/blogs/${item?.slug}`}>{item?.author.slug}</Link > */}
        </div>
      </CardBody>
    </Card>
  )
}

export default Songs