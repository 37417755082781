'use client'
import React from 'react'
import { getAllWithPagination } from '@src/app/actions';
import dynamic from 'next/dynamic';
import Songs from '@ui/atoms/cards/songs/Songs';
import SongsSkeleton from '@ui/atoms/cards/songs/SongsSkeleton';

const InfiniteScroll = dynamic(
  () => import('@ui/atoms/infinite-scroll/InfiteScroll'), {
  ssr: false,
  loading: () => null
});

function SongsInfiniteScroll(props: any) {
  const { lang, initialItems } = props;
  return (
    <InfiniteScroll
      lang={lang}
      slug={`songs`}
      initialItems={initialItems}
      getItems={getAllWithPagination}
      ContentCard={Songs}
      Skeleton={SongsSkeleton}
    />
  )
}

export default SongsInfiniteScroll