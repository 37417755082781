import Link from "@ui/atoms/link/Link";
import './Pill.css';
import styles from './Pill.module.css';

function Pill(props: any) {
  const { lang, variant, title, slug, count } = props;
  if (!variant || variant === 'default') {
    return (
      <div className={`${styles.pill}`}>
      <Link lang={lang} href={slug} >
        {`${title}`} {count ? `(${count})` : ''}
      </Link>
      </div>
    );
  } else if (variant === 'display-zero') {
    return (
      <div className={`${styles.pill}`}>
      <Link lang={lang} href={slug} >
        {`${title}`} ({count ? count : 0})
      </Link>
      </div>
    );
  } else if (variant === 'card-tag') {
    return (<Link
      style={{
        color: 'var(--card-tag-color)',
        background: 'var(--card-tag-bg-color)',
        marginRight: '1rem',
        padding: '0.1rem 0.5rem',
      }}
      href={slug}
    >
      {title}
    </Link>)
  }
}

export default Pill;
// <Link lang={lang}href={`/tags/${props.slug}`} href={props.slug} className="pills_item swiper-slide">{props.name}</Link>
// <Link lang={lang}href={`/categories/${slug}`} className="pills_item swiper-slide">{`${name} (${count})`}</Link>
