import React from 'react'
import styles from './ArtistName.module.css'
import Link from '../link/Link';

function ArtistName(props: any) {
  const { slug, artistName } = props;
  return (
    <Link
      className={styles.artistName}
      href={slug}
    >
      {artistName}
    </Link>
  )
}

export default ArtistName