import React from 'react'
import Link from "@ui/atoms/link/Link";
import Image from '@ui/atoms/image';
import styles from './Thumbnail.module.css';

function Thumbnail({
  lang,
  href,
  src,
  alt,
  defaultImage,
  width,
  height,
}: any) {

  return (
    <div className={styles.thumbnailContainer}>
      <Link className={styles.thumbnailLink} lang={lang} href={href}>
        <Image
          width={width ?? 266}
          height={height ?? 190}
          className={styles.thumbnailImage}
          src={src}
          alt={alt}
          loading="lazy"
          defaultImage={defaultImage}
        />
      </Link>
    </div>
  )
}

export default Thumbnail