import Pill from '@ui/atoms/pill/Pill'
import React from 'react'
import styles from "./CardCategories.module.css"
function CardCategories({ item, variant }: any) {
  return (
    <div className={styles.categories}>
      {item?.categories?.map((item: any, index: number) => (
        <Pill
          key={`${index}-${item.id}`}
          variant="card-tag"
          title={item.title}
          slug={`/${variant}?categories=${item.slug}`}
        />
        // {item?.tags?.map((item: any, index: number) => (
        //   <Pill
        //     key={`${index}-${item.id}`}
        //     variant="card-tag"
        //     name={item.title}
        //     slug={`/${variant}?tags=${item.slug}`}
        //   />
      ))}
    </div>
  )
}

export default CardCategories