
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"4e1dfc10fece32d1b4578dcd85b81ff9a464c6f2":"searchAllWithPagination","af8030017fa02aa3272c1a0de5f031f8cc01450d":"getOne","b11bb5d3271c9482e68824e23a1c56e8acbf35f1":"getSitemap","d1ecd7757e6eabf4f7c0ee81a63d2517220c184c":"getAllWithPagination"} */ export var getSitemap = createServerReference("b11bb5d3271c9482e68824e23a1c56e8acbf35f1");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getAllWithPagination = createServerReference("d1ecd7757e6eabf4f7c0ee81a63d2517220c184c");
export var searchAllWithPagination = createServerReference("4e1dfc10fece32d1b4578dcd85b81ff9a464c6f2");
export var getOne = createServerReference("af8030017fa02aa3272c1a0de5f031f8cc01450d");

