import LibLink from 'next/link'
import { i18n } from '@src/i18n-config'
interface LinkProps {
  href: string
  children: React.ReactNode
  [key: string]: any
}
export default function Link({ lang, href, ...props }: LinkProps) {
  const isDefaultLang = lang === i18n.defaultLocale
  const path = isDefaultLang ? href : (lang ? `/${lang}${href}` : (href || ''));
  return <LibLink href={path} {...props} />
}