import React from 'react';
import Skeleton from 'react-loading-skeleton';
const SongsSkeleton = (props: any) => {
  const { count } = props;
  return (
    <>
      {Array(count)
        .fill(null)
        .map((item, index) => (
          <div className="card_single" key={index}>
            <div className="jk-card card-img-top" key={index}>
              <Skeleton style={{ borderRadius: "1rem" }} height={180} />
              <div className="card-body">
                <div style={{ marginBottom: "1rem" }} className="media-body flex">
                  <Skeleton style={{ borderRadius: "0.5rem"}} height={'2.5rem'} containerClassName="flex-1 mr-1" />
                  <Skeleton style={{ borderRadius: "0.5rem"}} height={'2.5rem'} containerClassName="flex-1 mr-1" />
                  <Skeleton style={{ borderRadius: "0.5rem" }} height={'2.5rem'} containerClassName="flex-1" />
                </div>
                <Skeleton style={{ marginBottom: '1rem' }} height={'2.5rem'} />
                <div className="media-body flex">
                  <Skeleton style={{ borderRadius: "2rem"}} height={'2.5rem'} containerClassName="flex-1 mr-1" />
                  <Skeleton style={{ borderRadius: "2rem"}} height={'2.5rem'} containerClassName="flex-1 mr-1" />
                  <Skeleton style={{ borderRadius: "2rem" }} height={'2.5rem'} containerClassName="flex-1" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
export default SongsSkeleton;
