import Link from "../../link/Link"
import styles from './CardTitle.module.css'

function CardTitle({ title, href, lang, variant }: any) {
  return (
    <div title={title} className={styles.cardTitle}>
      <Link
        lang={lang}
        className={styles.cardTitleLink}
        href={href}
      >
        {title}
      </Link>
    </div>
  )
}

export default CardTitle