'use client';
import React, { useState } from 'react';
import NextImage from 'next/image';

function Image(props: any) {
  const { src, defaultImage, ...rest } = props;
  const [thumbnailError, setThumbnailError] = useState(false);

  const handleImageError = () => {
    setThumbnailError(true);
  };

  return (
    <>
      {!thumbnailError ? (
        <NextImage
          data-src={src}
          onError={handleImageError}
          src={src}
          {...rest}
        // placeholder='blur'
        // blurDataURL={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAYAAAB/qH1jAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAJ0lEQVR4nGPY2fXjv458/H9Bbtf/IDbD/7v//8/Mvfq/J+nEfxAbAF3NFsFiuaE1AAAAAElFTkSuQmCC'}
        // layout="fill"
        />
      ) : (
        <NextImage
          data-src={src}
          onError={handleImageError}
          src={defaultImage}
          {...rest}
        // placeholder='blur'
        // blurDataURL={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAYAAAB/qH1jAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAJ0lEQVR4nGPY2fXjv458/H9Bbtf/IDbD/7v//8/Mvfq/J+nEfxAbAF3NFsFiuaE1AAAAAElFTkSuQmCC'}
        // layout="fill"
        />
      )}
    </>
  );
}

export default Image;
