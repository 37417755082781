import React from 'react'
import styles from './Card.module.css'

function Card(props: any) {
  const { lastElementRef, children } = props;
  return (
    <div ref={lastElementRef} className={styles.card}>
      <div className={styles.body}>
        {children}
      </div>
    </div>
  )
}

export default Card